@import "src/assets/custom.scss";
































































































.tabwrap {
  @include mobile {
    order: 0;
  }
}

.handlung {
  @include mobile {
    order: 1;
    a {
      img {
        width: 50% !important;
      }
    }
  }
}

.tab {
  //align-items: flex-start;
  width: 50%;
  @include mobile {
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
  }

  img {
    width: 100%;
  }
}

h4.blau {
  color: darken(#6E889D, 10%);


  font-size: 20px;
  font-weight: 700;
}
