@import "src/assets/custom.scss";


























































































































































































.tellerbox {

  transition: transform 0.4s cubic-bezier(.78, 3, .7, 1);
}

.icon {
  transition: transform 0.4s cubic-bezier(.78, 3, .7, 1);
}

.move-enter {
  .tellerbox {
    /*transform: translateX(50px);*/
  }

  .icon {
    /*transform: translateX(50px);*/
  }
}

.move-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
  transform: translateX(-100%);
}

.move {

  display: flex;

  &.absolute {
    /*position: absolute;*/
    top: 0;
    width: 100%;

  }
}

.row {
  /*max-width: 100%;*/
  max-width: 1080px;
  width: 100%;
  position: relative;
}

.bg {
  background-size: cover;
  overflow: hidden;
  box-shadow: 0px 10px 5px 0 inset rgba(0, 0, 0, 0.05), 0px -10px 5px 0 inset rgba(0, 0, 0, 0.05);

}

.bg-gruen-half {
  /*background-image: url('@assets/') ;*/
  background-color: rgba(#9de269, 0.2);
}

.bg-gruen-full {
  /*background-image: url('@assets/') ;*/
  background-color: rgba(#9de269, 1);
}

.bg-orange-half {
  background-color: rgba(#f8daa8, 0.5);
}

.bg-orange-full {
  background-color: rgba(#f8daa8, 1);

}

.bg-violett-half {
  background-color: rgba(120, 54, 131, 0.2);
}

.bg-violett-full {
  background-color: rgba(120, 54, 131, 0.2);

}

.bg-blau-half {
  background-color: rgba(110, 136, 157, 0.35);
}

.bg-blau-full {
  background-color: rgba(110, 136, 157, 1);

}

.explainer {
  overflow: hidden;
  @include mobile {
    position: relative;
    padding: 10px 30px;

  }

  & > div {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
  }

  .orange {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background-color: rgba($orange, 0.9);
    padding: 20px 20px 40px 20px;
    color: #fff;
    font-size: 40px;
    font-weight: 600;
    z-index: 2;
    box-shadow: 5px 5px 5px 3px rgba(0, 0, 0, 0.1);
    @include mobile {
      padding: 20px 30px 40px 20px;
      transform: translate3d(-10px, 10px, 0);
      p {
        font-size: 14px;
        text-align: left;
        width: 100%;
        opacity: 0;
      }
    }
  }

  .gruen {
    font-weight: 600;
    z-index: 1;
    font-size: 14px;
    background-color: rgba($gruen, 0.8);
    transform: translate3d(0, 20px, 0) scale(1.05);
    transform-origin: right;
    /*transform: scale(1.1);*/
    text-align: left;
    box-shadow: 5px 5px 5px 3px rgba(0, 0, 0, 0.1);
    padding: 20px 50px 40px 60px;
    @include mobile {
      transform: translate3d(-30px, 0, 0) scale(0.9);
      transform-origin: right bottom;
      z-index: 3;
      position: absolute;
      bottom: 0;

    }
  }
}
