@import "src/assets/custom.scss";





























.icon {
    max-height: 50px;

    img {
       height: 50px;
    }
}
