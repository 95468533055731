@import "src/assets/custom.scss";




























































































































































































































































































.halfwidth {
  max-width: 50%;
  @include mobile {
    max-width: 100%;
  }
}
.mobilecol {
  @include mobile {

  flex-direction: column;
  }
}
.selection {
  justify-content: space-between;
  margin: 20px 0;

  .verticalline {
    background-color: #b9b9b9;
    width: 1px;
  }
}

.button {
  &.send {

  }
}


.group {
  display: flex;

  div {
    margin: 0 10px;
  }
}

.whitebox {
  width: 100%;
  text-align: left;
  padding: 32px;
}

.protection {
  &.red {
    border-color: red;

    label {
      color: red;

    }
  }
}

textarea {
  border-radius: 20px;
  appearance: none;
  border: 1px solid #B9B9B9;
  margin: 8px 0px;
  padding: 8px 16px;

  &:focus {
    outline: none;
  }
}

select {
  min-width: 260px;
  font-size: 14px;
  line-height: 20px;
  margin-left: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;

  &.red {
    border-color: red !important;
  }

  background: url('../assets/img/chevron-black.svg') no-repeat scroll right 16px center;

  &:focus {
    background: url('../assets/img/chevron.svg') no-repeat scroll right 16px center;

    outline: none;
    border-radius: 20px 20px 0 0;
    background-color: #3D3D3D;
    color: #fff;

    option {
      background-color: #fff;
      color: #3D3D3D;

      margin: 0 8px;


    }
  }

  option {

    &.disabled {
      color: lighten(#3D3D3D, 30%);
      //color: #fff;
    }
  }

  border-radius: 20px;
  padding: 8px 16px;

  &:invalid {
    opacity: 0.8;
  }

}

hr {
  border-top: 1px solid #B9B9B9;
}
