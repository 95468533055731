@import "src/assets/custom.scss";




















































































video {
    /*height: 500px;*/

   // @include mobile {
    //    height: 320px;
    //  }
}

.vwrap {
    div {
        @include mobile {
     //      max-height: 320px;
      //      max-width: 320px;
        }
    }

    overflow: hidden;
    /*max-width: 100%;*/
    position: relative;
    /*top: 0;*/
    width: calc(100% + 20px);
    margin-left: -10px;
    /*height: 480px;*/
    @include mobile {
     //   width: 320px;
       // height: 320px;
    }
    /*<!--z-index: -10;-->*/
}

