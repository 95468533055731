@import "src/assets/custom.scss";
















































































.menu {
  background-color: #fff;
  border-radius: 60px;

  margin-top: 50px;
  box-shadow: 5px 5px 5px 3px rgba(0, 0, 0, 0.1);
  @include mobile {
    border-radius: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
    justify-content: center !important;
  }

  padding: 5px 10px;
  /*height: 50px;*/
  .header {
    @include mobile {
      width: 100%;
      justify-content:flex-start!important;
    }
  }

}

.circles {
  @include mobile {
    justify-content: center;
    display: flex;
    padding-left: 10px;
  }
}

.title {
  margin-left: 20px;
  font-size: 24px;
  font-weight: 600;
  @include mobile {
    font-size: 18px;
    margin-left: 0;
  }

  &.gruen {
    color: $gruen;
  }

  &.orange {
    color: $orange;
  }

  &.violett {
    color: $violett;
  }

  &.blau {
    color: $blau;
  }
}
