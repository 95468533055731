@import "src/assets/custom.scss";






















































h4 {
    &.gruen {
        color: $gruen;
    }

    &.orange {
        color: $orange;
    }

    &.violett {
        color: $violett;
    }

    &.blau {
        color: $blau;
    }

    font-size: 20px;
    font-weight: 600;
    margin-top: 20px;

}

.teller {
    display: block;
    width: 100%;
    border-radius: 50%;
    box-shadow: 5px 5px 5px 3px rgba(0, 0, 0, 0.1);

}

.tellerbox {
    display: flex;

    cursor: pointer;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 30px 0px;
    @include mobile {
        justify-content: center !important;
    }

    & > div {
        position: relative;
        max-width: 190px;
        height: 190px;
        /*margin: auto;*/

    }

    &.hovering {
        .icon {
            transform: scale(1.1);
        }
    }
}

.tellerbox {
}

.icon {
    width: 100%;
    height: 100%;
    top: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}
