@import "src/assets/custom.scss";


















































.circleicon {
    margin: 5px;
    height: 40px;
    cursor: pointer;
    width: 40px;
    padding: 3px;
    border: 1px solid rgba(black,0.2);
    /**/

    transition: all 1s ease;
    opacity: 1;
    &.gruen {
        border-color: $gruen;
    }
    &.orange {
        border-color: $orange;

    }
  &.violett {
        border-color: $violett;

    }
    &.blau {
        border-color: $blau;
    }
    &.active {
        //border-width: 2px;
        //opacity: 1;
        //transform: scale(1.1);
      border-color: #fff;
      &.gruen {
        background-color: $gruen;
      }
      &.orange {
        background-color: $orange;

      }
      &.violett {
        background-color: $violett;

      }
      &.blau {
        background-color: $blau;
      }
    }
    img {

    }
}
