@import "src/assets/custom.scss";





























































































































.intro {
    @include mobile {
        font-size: 14px;
    }
}
.imgwrap {
    @include mobile {
        justify-content: center!important;

    }
}.boxwrap {
    @include mobile {
        margin-left: 0;
        margin-right: 0;

    }
}
.dokument {
    padding: 20px 10px;
    font-size: 14px;

    &:first-child {
        border: none !important;
    }

    &.orange {
        border-top: 1px solid $orange;
    }

    &.gruen {
        border-top: 1px solid $gruen;
    }
}

.pic {
    padding: 5px;
}

a {
    color: inherit;
}

.whitebox {
    transition: transform 0.6s cubic-bezier(.78,2,1, 1) ;
    background-color: #fff;
    margin: 20px 0;
    border-radius: 20px;
    box-shadow: 5px 5px 5px 3px rgba(0, 0, 0, 0.1);
    & > div {
        min-height: 10px;
    }

    .img {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;

        img {
            display: block;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            width: 100%;
        }

    }

    .text {
        padding: 20px 25px;
        font-size: 14px;

    }
}

.text {
    &.orange {
        border-top: 1px solid $orange;
    }

    &.gruen {

        border-top: 1px solid $gruen;
    }
}
