@import "src/assets/custom.scss";

















































































































.mobilecol {
  @include mobile {
    flex-direction: column;
  }
}

.extra {
  margin-top: 20px;

  textarea {
    border-radius: 20px;
    outline: none !important;
    padding: 15px;
  }
}

.field {
  label {

    img.active {
      display: none
    }
  }

  &.active {

    label {
      font-weight: bold;
      border: 1px black;

      img {
        display: none;
      }

      img.active {
        display: inline-flex;
      }
    }
  }
}

.number {

}

.questions {
  justify-content: space-between;
  align-items: center;
  padding: 28px 0 28px 0;
  border-top: 1px #B9B9B9 solid;
  border-bottom: 1px #B9B9B9 solid;

  .question {
    width: 55%;
    @include mobile {
      width: 100%;
    }
  }
  .answer {
    width: 40%;
    @include mobile {
      width: 80%;
    }
  }

  .number {
    //styleName: <h1>;
    //font-family: Roboto;
    font-size: 42px;
    font-style: normal;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: 0.02em;
    text-align: center;
    min-width: 100px;
    @include mobile {
      width: auto;
      min-width: 50px;
    }
  }
}

.field.last {
  margin-left: 20px;
  padding-left: 30px;
  border-left: 1px solid #B9B9B9;
}

.whitebox.fragebogen {
  padding: 0;

  .inner {
    padding: 32px;
    text-align: left;
    min-height: 0;

    &.closed {
      height: 0;
      padding: 0;
      overflow: hidden;
    }
  }
}

h4.white {
  color: #fff;
  margin-bottom: 0;
}

.head {
  padding: 5px 10px;
  border-radius: 20px 20px 0 0;

  &.ausschuss {
    background-color: $blau;
  }

  &.checks {
    background-color: $gruen;
  }

  &.befragungen {
    background-color: $violett;
  }

  &.messungen {
    background-color: $orange;
  }


}
