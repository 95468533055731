@import "src/assets/custom.scss";






























































































.messungen {
    margin: auto;

    & > div {
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

img {
    max-width: 100%;
}

.tablet {
    transition: transform 0.5s linear 0.2s;
    transform: rotate(0deg);
    align-items: start;

    img {
        margin: auto;
    }

    @include mobile {
        align-items: center;
    }
}


